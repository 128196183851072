<template >
    <section class="g_header">
        <div class="g_headerLeft">
            <h1>{{ pageTitle }}</h1>
        </div>
        <div class="g_headerMid">
            <img src="../../assets/logo.png" alt="">
        </div>
        <div class="g_headerRight">
            <img src="" alt="User Image">
            <h1> User Name </h1>
        </div>
    </section>
</template>

<script>
    export default{
        name:'giftg_header',
        props: {
    pageTitle: {
      type: String,
      required: true,
    },
    },
        data(){
            return {
                mainUser:"",
                imgUrl:""
            };
        },
         mounted() {
            
        },
  
        
    };
</script>


<style>

/* :root {
  --g-bg-color: #86A789 !important;;
  --g-bg2-color: #B2C8BA;
  --g-light-color: #D2E3C8;
  --g-Snd-color: #EBF3E8;
  --g-trd-color: #B2C8BA;
  --g-text-color: #fff;
  --g-black: #000; 
} */

/* :root {
  --g-bg-color: #ED7D31 !important;;
  --g-bg2-color: #994D1C;
  --g-light-color: #E48F45;
  --g-Snd-color: #F5CCA0;
  --g-trd-color: #994D1C;
  --g-text-color: #fff;
  --g-black: #000; 
} */

:root {
  --g-bg-color: #164863 !important;;
  --g-bg2-color: #427D9D;
  --g-light-color: #9BBEC8;
  --g-Snd-color: #DDF2FD;
  --g-trd-color: #427D9D;
  --g-text-color: #fff;
  --g-black: #000; 
}

.g_header{
    color: rgb(255, 255, 255); 
    height: 140px;
    background-color: var(--g-bg2-color);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.g_header .g_headerLeft{
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* background-color: var(--text-color); */
  align-items: center;
  color: var(--g-text-color);
}
.g_header .g_headerLeft img{
  height: 50px;
  margin: 2px;
  border-radius: 5px;
  /* background-color: var(--text-color); */
  /* box-shadow: 2px 3px 4px var(--black); */

}

.g_header .g_headerLeft h1{
  
  border-radius: 5px;
  padding: 0.2px 3px;
  background-color: var(--g-bg-color);
  box-shadow:  2px 2px 4px var(--g-black);
  font-size: 18px;
}
.g_header .g_headerMid{
    padding: 3px;
}

.g_header .g_headerMid img{
    height: 120px;

}

.g_header .g_headerRight{
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.8px;
  
  box-shadow:  2px 2px 4px var(--g-black);
  
  background-color: var(--g-bg-color);
  color: var(--g-text-color);
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.g_header .g_headerRight h1{
  font-size: 18px;
  margin-left: 5px;
}
.g_header .g_headerRight img{
  height: 40px;
  width: 40px;
  margin-right: 10px;
}




</style>