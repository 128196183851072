<template>
  <Header :pageTitle="pgTitle" />
  <section class="g_main">
    <side_bar />
    <div class="g-boxCentre" style="margin-top: 20px">
      <div class="g-userForm" style="margin-top: 80px">
        <h1 style="margin-top: 0px">Add User</h1>
        <h2>{{ msg }}</h2>
        <h5
          v-if="imageUrl"
          crossorigin="anonymous"
          style="text-align: left; margin-left: 75px; height: 100px"
        >
          <img
            style="margin-top: 10px; height: 100px"
            v-if="imageUrl"
            :src="imageUrl"
            alt="Selected Image"
          />
        </h5>
        <h5 style="text-align: right; margin-right: 100px; font-size: 14px">
          * Required
        </h5>
        <form @submit.prevent="confirmMakeUser">
          <div class="g-formBox">
            <span>First Name*</span>
            <input type="text" v-model="fname" id="uFName" required />
          </div>
          <div class="g-formBox">
            <span>Last Name</span>
            <input type="text" v-model="lname" id="uLName" />
          </div>
          <div class="g-formBox">
            <span>Designation*</span>
            <input
              type="text"
              v-model="designation"
              id="uDesignation"
              required
            />
          </div>
          <div class="g-formBox">
            <span>Mail*</span>
            <input type="text" v-model="mail" id="uMail" required />
          </div>

          <div class="g-formBox">
            <span>Number*</span>
            <input type="text" v-model="number" id="uNumber" required />
          </div>

          <div class="g-formBox">
            <span>Role*</span>
            <select v-model="selectedOption" required>
              <option value="SUPER_ADMIN">Super Admin</option>
              <option value="DEPUTY_ADMIN">Deputy Admin</option>
              <option value="ADMIN">Admin</option>
              <option value="EMPLOYEE">Employee</option>
            </select>
          </div>

          <div class="formBox1" v-if="selectedOption == 'EMPLOYEE'">
            <label>
              <input
                type="checkbox"
                v-model="checkedPermissions"
                :value="'MAKE_USER'"
              />
              Make User
            </label>

            <label>
              <input
                type="checkbox"
                v-model="checkedPermissions"
                :value="'BOOK_ENTRY'"
              />
              Book Entry
            </label>

            <label>
              <input
                type="checkbox"
                v-model="checkedPermissions"
                :value="'ADD_LIBRARY'"
              />
              Add Library
            </label>

            <label>
              <input
                type="checkbox"
                v-model="checkedPermissions"
                :value="'LIBRARY_CATEGORY'"
              />
              Library Category
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                v-model="checkedPermissions"
                :value="'DISTRIBUTION'"
              />
              Distribution & Bill
            </label>

            <label> </label>

            <label>
              <input
                type="checkbox"
                v-model="checkedPermissions"
                :value="'REPORT'"
              />
              Reports
            </label>
          </div>

          <div class="g-formBox">
            <span>User Name*</span>
            <input type="text" v-model="userName" id="uUserName" required />
          </div>

          <div class="g-formBox">
            <span>Password*</span>
            <input type="password" v-model="password" id="uPassword" required />
          </div>

          <div class="g-formBox">
            <span>Upload Picture</span>
            <input type="file" name="image" @change="onFileChange" />
          </div>

          <button type="submit">Create User</button>
        </form>
      </div>
    </div>
  </section>

  <teleport to="body">
    <div class="g-bgpop" v-if="showBook">
      <div class="g-popBg">
        <h2>Book Details Confirmation of {{ economicYear }}</h2>

        <div class="g-popText">
          <span>Work Order:</span>
          <p>{{ workOrder }}</p>
        </div>

        <div class="g-popText">
          <span>Date:</span>
          <p>{{ date }}</p>
        </div>

        <div class="g-popText">
          <span>Title:</span>
          <p>{{ bookName }}</p>
        </div>

        <div class="g-popText">
          <span>Author:</span>
          <p>{{ authorName }}</p>
        </div>

        <div class="g-popText">
          <span>Publisher:</span>
          <p>{{ publisher }}</p>
        </div>

        <div class="g-popText">
          <span>Book Type:</span>
          <p>{{ selectedType }}</p>
        </div>

        <div class="g-popText">
          <span>ISBN:</span>
          <p>{{ isbn }}</p>
        </div>

        <div class="g-popText">
          <span>Year:</span>
          <p>{{ year }}</p>
        </div>

        <div class="g-popText">
          <span v-if="issn">ISSN:</span>
          <p v-if="issn">{{ issn }}</p>
          <span v-if="edition">Edition:</span>
          <p v-if="edition">{{ edition }}</p>
        </div>

        <div class="g-popText">
          <span>Cover Type:</span>
          <p>{{ cover }}</p>
          <span>Pages:</span>
          <p>{{ pages }}</p>
        </div>

        <div class="g-popText">
          <span>Price:</span>
          <p>{{ price }}</p>
          <span>Copy:</span>
          <p>{{ copy }}</p>
        </div>

        <div class="g-popText">
          <span>Details:</span>
          <p>{{ details }}</p>
        </div>

        <div class="g-actionButtons">
          <button @click="book_Entry" class="confirm">Confirm</button>
          <button @click="handleCancel" class="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="g-bgpop" v-if="confrimAddition">
      <div class="g-popBg">
        <h2>Success Massage</h2>

        <div class="g-popText">
          <p>Book Added Successfully........</p>
        </div>

        <div class="g-actionButtons">
          <button @click="reload()" class="confirm">Ok</button>
        </div>
      </div>
    </div>
  </teleport>

  <gfooter />
</template>

<script>
import axios from "axios";
import gfooter from "./sections/g-footer.vue";
import Header from "./sections/g_header.vue";
import side_bar from "./sections/g_side_bar.vue";
export default {
  name: "createUser",
  components: {
    Header,
    side_bar,
    gfooter,
  },
  data() {
    return {
      pgTitle: "Support Book Entry",
      showBook: false,
      isLoading: false,
      workOrder: "",
      date: "",
      bookType: "",
      sType: "",
      pages: "",
      bookName: "",
      authorName: "",
      isbn: "",
      issn: "",
      details: "",
      publisher: "",
      year: "",
      copy: "",
      price: "",
      cover: "",
      edition: "",
      books: [],
      serialNo: "",
      economicYear: "",
      selectedType: "",
      showError: false,
      confrimAddition: false,
      errorMsg: "",
      selectedFile: null,
      serialDigits: [
        undefined,
        undefined,
        ".",
        undefined,
        undefined,
        undefined,
        undefined,
      ],
    };
  },
  methods: {
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
      console.log("Selected File:", this.selectedFile); // Debugging line
    },

    handleInput(event, index) {
      const value = event.target.value;

      if (value && index < 6) {
        if (Number.isInteger(Number(value))) {
          const nextInput = event.target.nextElementSibling;
          if (nextInput) {
            nextInput.focus();
          }
        } else {
          this.showErrorAndSetMessage(
            "Serial number must be a number. It cannot be a character"
          );
          this.serialDigits = [
            undefined,
            undefined,
            ".",
            undefined,
            undefined,
            undefined,
            undefined,
          ];
        }
      } else {
        if (!Number.isInteger(Number(value))) {
          this.showErrorAndSetMessage(
            "Serial number must be a number. It cannot be a character"
          );
          this.serialDigits = [
            undefined,
            undefined,
            ".",
            undefined,
            undefined,
            undefined,
            undefined,
          ];
        }

        const arr = this.serialDigits;
        this.serialNo = arr.join("");
      }
    },
    showErrorAndSetMessage(message) {
      this.showError = true;
      this.errorMsg = message;
    },
    reload() {
      window.location.reload();
    },
    handleCancel() {
      this.showBook = false;
    },
    async bookConfirm() {
      console.log("Book Name:", this.bookName); // Debugging line

      if (!this.bookName || !this.selectedFile) {
        this.uploadStatus = "Title and file are required.";
        return;
      }

      const formData = new FormData();
      formData.append("title", this.bookName);

      console.log(formData);
      try {
        const response = await axios.post("pandulipi/upload-files", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.ok) {
          this.uploadStatus = "File uploaded successfully!";
        } else {
          this.uploadStatus = "File upload failed.";
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        this.uploadStatus = "File upload failed.";
      }
    },
  },

  async mounted() {
    try {
      const storedToken = localStorage.getItem("authToken");
      this.economicYear = localStorage.getItem("economicYear");
      if (storedToken) {
        this.$store.commit("setToken", storedToken);
      }
    } catch (error) {
      this.isLoading = false;
      this.showError = true;
      this.errorMsg = error.response.data.message;
    }
  },
};
</script>

<style>
.g-boxCentre {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: auto;
}

.g-profilePic {
  height: 80px;
}

.g_main .g-userFrom {
  background-color: var(--g-light-color);
  width: 700px;
  height: auto;
  margin-top: 1.5%;
  margin-left: 2.5%;
  border-radius: 25px;
  margin-bottom: 25px;
}

.g_main .g-userFrom h1 {
  background-color: var(--g-trd-color);
  border-radius: 25px 25px 0px 0px;
  text-align: center;
  padding: 5px;
  color: var(--g-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
}
.g_main .g-userFrom h2 {
  border-radius: 25px 25px 0px 0px;
  text-align: right;
  padding-right: 25px;
  color: var(--g-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
}

.g_main .g-userFrom form {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.g_main .g-userFrom form .g-formBox {
  margin-top: 5px;
  padding: 5px;
  width: 85%;
  border-radius: 25px;
  padding-left: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  flex-direction: row;
}

.g_main .g-userFrom form .g-formBox span {
  margin: 1px;
  margin-left: 10px;
  height: 40px;
  padding-left: 20px;
  width: 30%;
  color: var(--g-bg-color);
  background-color: var(--g-text-color);
  box-shadow: inset 3px 3px 6px var(--black);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
}
.g_main .g-userFrom form .g-formBox input,
select,
p {
  margin: 1px;
  height: 40px;
  padding-left: 20px;
  text-align: left;
  width: 55%;
  color: var(--black);
  background-color: var(--g-text-color);
  box-shadow: inset 3px 3px 6px var(--black);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;

  display: flex;
  align-items: center;
}
.g_main .g-userFrom form .g-formBox select {
  margin: 1px;
  height: 40px;
  border-radius: 0px;
  padding-left: 20px;
  text-align: left;
  width: 59%;
  color: var(--black);
  background-color: var(--g-text-color);
  box-shadow: inset 3px 3px 6px var(--black);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;

  display: flex;
  align-items: center;
}
.g_main .g-userFrom form .g-formBox1 {
  margin-top: 15px;
  width: 85%;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
.g_main .g-userFrom form .g-formBox1 label {
  margin-left: 10px;
  width: 20%;
  text-align: center;
  padding: 0.1%;
  color: var(--g-bg-color);
  background-color: var(--g-text-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.1px;
}
.g_main .g-userFrom button {
  margin-top: 4%;
  margin-bottom: 2%;
  padding: 0.5% 7%;
  height: 40px;
  color: var(--g-trd-color);
  font-size: 15px;
  font-weight: 450;
  background-color: var(--g-text-color);
  box-shadow: -3px 3px 6px var(--g-Snd-color);
  border-radius: 20px;
}
.g_main .g-userFrom button:hover {
  padding: 0.5% 5%;
  color: var(--g-text-color);
  font-size: 20px;
  background-color: var(--g-trd-color);
  box-shadow: -3px 3px 6px var(--g-bg-color);
  letter-spacing: 2px;
  transition: 0.5s ease-in;
  cursor: pointer;
}
.g-btn {
  margin-top: 4%;
  margin-bottom: 2%;
  padding: 0.5% 7%;
  height: 40px;
  text-decoration: none;
  color: var(--g-trd-color);
  font-size: 18px;
  font-weight: 450;
  background-color: var(--g-text-color);
  box-shadow: -3px 3px 6px var(--g-Snd-color);
  border-radius: 20px;
}

.g-btn:hover {
  padding: 0.5% 5%;
  color: var(--g-text-color);
  font-size: 20px;
  background-color: var(--g-trd-color);
  box-shadow: -3px 3px 6px var(--g-bg-color);
  letter-spacing: 2px;
  transition: 0.5s ease-in;
  cursor: pointer;
}

.g-bgpop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ae;
}
.g-popBg {
  position: relative;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.g-popBg2 {
  position: relative;
  max-width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.g-popBg h2 {
  text-decoration: none;
  font-size: 18px;
  color: var(--g-black);
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
  border-bottom: 2px dotted var(--g-black);
}
.g-popBg2 h2 {
  text-decoration: none;
  font-size: 18px;
  color: var(--g-trd-color);
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
  border-bottom: 7px dotted var(--g-trd-color);
}

.g-popBg .g-popText {
  width: 600px;
  height: 40px;
  border: 1px solid black;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  text-align: left;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.g-popBg .g-popText span {
  text-align: left;
  width: 150px;
  padding: 2px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.g-popBg .g-popText p {
  margin: 0;
  background-color: var(--g-bg2-color);
  color: var(--g-text-color);
  text-align: left;
  height: 40px;
  width: 450px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  box-shadow: none;
  border: 0px;
}

.errorMsg {
  color: red;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.3px;
  box-shadow: none;
}
.successMsg {
  color: green;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.3px;
  box-shadow: none;
}
.g-popText input,
select {
  height: 35px;
  padding-left: 20px;
  width: 350px;
  border: 1px solid var(--g-black);
  box-shadow: 2px 2px 6px var(--g-black);
}

.g-popBg .g-actionButtons {
  margin: 2px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.g-popBg .g-actionButtons .confirm {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: rgb(0, 165, 0);
  box-shadow: 1px 2px 7px var(--black);
  cursor: pointer;
  transition: 0.3s ease-in;
}

.g-popBg .g-actionButtons .Delete,
.cancel {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: red;
  box-shadow: 1px 2px 7px var(--black);
  cursor: pointer;
  transition: 0.3s ease-in;
}
.g-popBg .g-actionButtons .confirm:hover {
  background-color: green;
}

.g-popBg .g-actionButtons .cancel:hover {
  background-color: red;
}

.g-popBg2 .g-actionButtons {
  margin: 2px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.g-popBg2 .g-actionButtons .confirm {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: var(--light-color);
  box-shadow: 1px 2px 7px var(--black);
  cursor: pointer;
  transition: 0.3s ease-in;
}
.g-popBg2 .g-actionButtons .Delete,
.cancel {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: rgb(213, 0, 0);
  box-shadow: 1px 2px 7px var(--black);
  cursor: pointer;
  transition: 0.3s ease-in;
}
.g-popBg2 .g-actionButtons .confirm:hover {
  background-color: green;
}

.g-popBg2 .g-actionButtons .cancel:hover {
  background-color: red;
}
</style>
