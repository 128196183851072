<template >
    <section class="footer">
        
    <h1 >© Copyright <span>Hakkani Publishers </span>. <br> All Rights Reserved.</h1>
    <img src="" alt="">
    <h1>Developed By <span> Digital Solution Bangladesh</span></h1>
    </section>
</template>

<script>
    export default{
        name:'foot_er',
      
    };
      
</script>


<style>
:root {
  --g-bg-color: #164863 !important;;
  --g-bg2-color: #427D9D;
  --g-light-color: #9BBEC8;
  --g-Snd-color: #DDF2FD;
  --g-trd-color: #427D9D;
  --g-text-color: #fff;
  --g-black: #000; 
}

.g-footer{
  background-color: var(--g-bg2-color);
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.footer h1{
  color: var(--g-text-color);
  font-size: 24px;
  letter-spacing: 1.2px;
  font-weight: 500;

}
.footer h1 span{
  text-shadow: 2px 2px 3px var(--g-black);
}

.footer img{
  height: 180px;
}

</style>