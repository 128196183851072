import book_edit from "./components/book/g_book_edit.vue";
import book_list from "./components/book/g_book_list.vue";
import book_entry from "./components/book/g_entry.vue";
import dasboard from "./components/dashboard.vue";
import Login from "./components/log_in.vue";

import { createRouter, createWebHistory } from "vue-router";

import createUser from "./components/createUser.vue";
const routes = [

   
    {
        name: "Login",
        component: Login,
        path: "/"
    },
    {
        name: "bookEdit",
        component: book_edit,
        path: "/bookEdit"
    },
    {
        name: "bookEntry",
        component: book_entry,
        path: "/bookEntry"
    },
    {
        name: "booklist",
        component: book_list,
        path: "/booklist"
    },
    {
        name: "dasboard",
        component: dasboard,
        path: "/dashboard"
    },
    {
        name: "createUser",
        component: createUser,
        path: "/createUser"
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;