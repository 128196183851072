<template>
  <Header :pageTitle="pgTitle" />
  <section class="g_main">
    <side_bar />

    <div class="g-bookList">
      <div class="g-bookForm">
        <h1>Book Search</h1>
        <div class="alphabet-row">
          <button @click="changeBd">Bangla</button>
          <button @click="change">English</button>
        </div>
        <form @submit.prevent="book_search">
          <div v-if="eng" class="alphabet-row">
            <button
              v-for="letter in alphabet"
              :key="letter"
              @click="filterByLetter(letter)"
            >
              {{ letter }}
            </button>
            <br />
          </div>
          <div v-else class="alphabet-row">
            <button v-for="(letter, index) in banglaAlphabet" :key="letter">
              {{ letter }}
              <!-- Add a line break after চ -->
              <br
                v-if="letter === 'চ' && index !== banglaAlphabet.length - 1"
              />
            </button>
            <br />
          </div>
        </form>
      </div>

      <div class="g-searchedBooks">
        <h1>Book List {{ letter }}</h1>
        <div class="g-Blist">
          <table>
            <tr>
              <th
                class="sl"
                style="border-color: var(--bg-color); color: var(--bg-color)"
              >
                Sl.
              </th>
              <th style="border-color: var(--bg-color); color: var(--bg-color)">
                Book Name
              </th>
              <th style="border-color: var(--bg-color); color: var(--bg-color)">
                Aurthor Name
              </th>
              <th style="border-color: var(--bg-color); color: var(--bg-color)">
                ISBN
              </th>
              <th style="border-color: var(--bg-color); color: var(--bg-color)">
                Type
              </th>

              <th style="border-color: var(--bg-color); color: var(--bg-color)">
                Action Button
              </th>
            </tr>
            <!-- <tr v-for="(book, index) in paginatedBooks" :key="book.bookId"> -->
            <tr v-for="(book, index) in filteredBooks" :key="index">
              <td style="border-color: var(--bg-color)">{{ index + 1 }}</td>
              <td style="border-color: var(--bg-color)">{{ book.bookName }}</td>
              <td style="border-color: var(--bg-color)">
                {{ book.authorName }}
              </td>
              <td style="border-color: var(--bg-color)">{{ book.ISBN }}</td>
              <td style="border-color: var(--bg-color)">{{ book.type }}</td>

              <td style="border-color: var(--bg-color)">
                <a href="/book_update/xykGDsiHmMmtZrXnHkqyoG" class=""
                  ><button class="edit" style="color: var(--bg-color)">
                    Share
                  </button></a
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </section>

  <foot_er />
</template>

<script>
import foot_er from "../sections/g-footer.vue";
import Header from "../sections/g_header.vue";
import side_bar from "../sections/g_side_bar.vue";

export default {
  name: "gg-bookList",
  components: {
    Header,
    side_bar,
    foot_er,
  },
  data() {
    return {
      pgTitle: " Book Lists",
      eng: true,
      alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
      banglaAlphabet: [
        "অ",
        "আ",
        "ই",
        "ঈ",
        "উ",
        "ঊ",
        "ঋ",
        "এ",
        "ঐ",
        "ও",
        "ঔ",
        "ক",
        "খ",
        "গ",
        "ঘ",
        "ঙ",
        "চ",
        "ছ",
        "জ",
        "ঝ",
        "ঞ",
        "ট",
        "ঠ",
        "ড",
        "ঢ",
        "ণ",
      ],
      letter: "",
      filteredBooks: [],
      books: [
        {
          bookName: " Great Gatsby",
          authorName: "F. Scott Fitzgerald",
          ISBN: "9780743273565",
          type: "Fiction",
          publisher: "Scribner",
        },
        {
          bookName: "Kill a Mockingbird",
          authorName: "Harper Lee",
          ISBN: "9780061120084",
          type: "Fiction",
          publisher: "Harper Perennial Modern Classics",
        },
        {
          bookName: "C 1984",
          authorName: "George Orwell",
          ISBN: "9780451524935",
          type: "Fiction",
          publisher: "Signet Classic",
        },
        {
          bookName: "Catcher in the Rye",
          authorName: "J.D. Salinger",
          ISBN: "9780316769488",
          type: "Fiction",
          publisher: "Little, Brown and Company",
        },
        {
          bookName: "Pride and Prejudice",
          authorName: "Jane Austen",
          ISBN: "9780141439518",
          type: "Fiction",
          publisher: "Penguin Classics",
        },
        {
          bookName: "Harry Potter and the Philosopher's Stone",
          authorName: "J.K. Rowling",
          ISBN: "9781408855652",
          type: "Fantasy",
          publisher: "Bloomsbury Publishing",
        },
        {
          bookName: "Hobbit",
          authorName: "J.R.R. Tolkien",
          ISBN: "9780345339683",
          type: "Fantasy",
          publisher: "Del Rey",
        },
        {
          bookName: "Lord of the Rings",
          authorName: "J.R.R. Tolkien",
          ISBN: "9780618645619",
          type: "Fantasy",
          publisher: "Houghton Mifflin Harcourt",
        },
        {
          bookName: "The Da Vinci Code",
          authorName: "Dan Brown",
          ISBN: "9780307474278",
          type: "Mystery",
          publisher: "Anchor Books",
        },
        {
          bookName: "Angels & Demons",
          authorName: "Dan Brown",
          ISBN: "9781416524793",
          type: "Mystery",
          publisher: "Pocket Books",
        },
        {
          bookName: "The Girl with the Dragon Tattoo",
          authorName: "Stieg Larsson",
          ISBN: "9780307454546",
          type: "Mystery",
          publisher: "Vintage Crime/Black Lizard",
        },
        {
          bookName: "The Hunger Games",
          authorName: "Suzanne Collins",
          ISBN: "9780439023481",
          type: "Science Fiction",
          publisher: "Scholastic Press",
        },
        {
          bookName: "Dune",
          authorName: "Frank Herbert",
          ISBN: "9780441172719",
          type: "Science Fiction",
          publisher: "Ace Books",
        },
        {
          bookName: "Neuromancer",
          authorName: "William Gibson",
          ISBN: "9780441569595",
          type: "Science Fiction",
          publisher: "Ace Books",
        },
        {
          bookName: "The Martian",
          authorName: "Andy Weir",
          ISBN: "9780553418026",
          type: "Science Fiction",
          publisher: "Crown Publishing Group",
        },
        {
          bookName: "The Road",
          authorName: "Cormac McCarthy",
          ISBN: "9780307387899",
          type: "Dystopian",
          publisher: "Vintage Books",
        },
        {
          bookName: "Brave New World",
          authorName: "Aldous Huxley",
          ISBN: "9780060850524",
          type: "Dystopian",
          publisher: "Harper Perennial Modern Classics",
        },
        {
          bookName: "Fahrenheit 451",
          authorName: "Ray Bradbury",
          ISBN: "9781451673319",
          type: "Dystopian",
          publisher: "Simon & Schuster",
        },
        {
          bookName: "The Handmaid's Tale",
          authorName: "Margaret Atwood",
          ISBN: "9780385490818",
          type: "Dystopian",
          publisher: "Anchor Books",
        },
        {
          bookName: "The Road Less Traveled",
          authorName: "M. Scott Peck",
          ISBN: "9780671250676",
          type: "Self-help",
          publisher: "Simon & Schuster",
        },
      ],
    };
  },
  methods: {
    change() {
      this.eng = true;
    },
    changeBd() {
      this.eng = false;
    },
    filterByLetter(letter) {
      this.letter = letter;
      this.filteredBooks = this.books.filter((book) =>
        book.bookName.toUpperCase().startsWith(letter)
      );
    },
  },
};
</script>

<style>
.alphabet-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.alphabet-row button {
  cursor: pointer;
  margin: 0 7px;
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 3px 3px 6px #000;
  color: #fff;
  background-color: #333;
  transition: 0.3s ease-in-out;
}
.alphabet-row button:hover {
  background-color: white;
  color: #333;
  box-shadow: inset 3px 3px 6px #333;
}
.g_main {
  background-color: var(--g-bg-color);
  height: 100%;
  padding: 0;
  border: none;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.g_main .g-bookList {
  width: 80%;
  height: 100%;
  margin-top: 2%;
  margin-left: 2.5%;
  margin-right: 1%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.g_main .g-bookList .g-bookForm {
  width: 100%;
  height: 150px;
  border-radius: 25px;
  background-color: var(--g-light-color);
}

.g_main .g-bookList .g-bookForm h1 {
  height: 25px;
  background-color: var(--g-trd-color);
  border-radius: 25px 25px 0px 0px;
  text-align: center;
  padding: 5px;
  color: var(--g-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
}

.g_main .g-bookList .g-bookForm form {
  display: flex;
  justify-content: center;
  justify-items: start;
  flex-direction: column;
  flex-wrap: nowrap;
}
.g_main .g-bookForm form .g-formBox {
  margin-top: 4px;
  padding: 5px;
  height: 40px;
  width: 85%;
  border-radius: 25px;
  padding-left: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}
.g_main .g-bookList .g-bookForm .g-searchForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.g_main .g-bookForm form .g-searchField {
  position: relative;
  width: 75%;
}
.g_main .g-bookForm form .g-searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 25%;
}

.g_main .g-bookForm form .g-searchButton .g-btn2 {
  cursor: pointer;
  width: 150px;
  height: 40px;
  font-size: 20px;
  padding: 1px;
  letter-spacing: 1.2px;
  background-color: var(--g-trd-color);
  color: var(--g-text-color);
  transition: 0.2s ease;
}

.g_main .g-bookForm form .g-searchButton .g-btn2:hover {
  width: 160px;
  height: 45px;
  font-size: 22px;
  padding: 1px;
  letter-spacing: 1.5px;
  background-color: var(--g-text-color);
  color: var(--g-trd-color);
}

.g_main .g-bookForm form .g-box1,
.g-box2 {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.g_main .g-bookForm form input,
select {
  margin: 2px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin-right: 30px;
  margin-top: 10px;
  width: 260px;
  height: 25px;
  color: var(--g-black);
  background-color: var(--g-text-color);
  box-shadow: 3px 3px 6px var(--g-black);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.1px;
}

.g_main .g-searchedBooks {
  display: block;
  background-color: var(--g-light-color);
  width: 100%;
  height: 100%;
  margin-top: 0.5%;
  margin-right: 0.5%;
  border-radius: 25px;
  padding-bottom: 50px;
  margin-bottom: 25px;
}

.g_main .g-searchedBooks h1 {
  width: auto;
  background-color: var(--g-trd-color);
  border-radius: 25px 25px 0px 0px;
  text-align: center;
  padding: 5px;
  color: var(--g-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
}

.g_main .g-searchedBooks .g-Blist {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.g-table {
  background-color: #fff;
  margin-top: 1.5%;
  width: 95%;
  padding: 1%;
  padding-top: 2%;
  padding-bottom: 4%;
  border-radius: 5px;
  box-shadow: inset -3px 3px 6px var(--g-bg-color);
}

.g-table .g-th {
  color: var(--g-bg-color);
  width: 30%;
  font-size: 16px;
  font-weight: 700;
  border: 2px solid var(--g-bg-color);
  border-radius: 5%;
  text-align: center;
  padding: 0.2%;
}

.g-table .g-th,
.g-td .sl {
  width: 4px;
  padding: 4px 10px;
}

.g-table .g-td {
  font-size: 14px;
  font-weight: 450;
  text-align: center;
  border: 2px solid var(--g-bg-color);
  border-top: 0;
  padding: 1px 1px;
}

.g-table .g-td .g-Bdel,
.g-Bedit {
  padding: 2px 8px;
  text-decoration: none;
  margin: 3px 2px;
  height: 25px;
  margin-top: 5px;
  color: var(--g-text-color);
  font-size: 14px;
  font-weight: 500;
  background-color: var(--g-light-color);
  border-radius: 5%;
  cursor: pointer;
  transition: all 0.5s linear;
}

.g-table .g-td .g-Bedit:hover {
  background-color: green;
}
.g-table .g-td .g-Bdel:hover {
  background-color: red;
  letter-spacing: 1.3px;
}

.g-pagination {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.g-pagination button {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: #333;
  cursor: pointer;
}

.g-pagination .currentPage {
  padding: 8px 12px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  background-color: var(--g-trd-color);
  color: var(--g-text-color);
  cursor: pointer;
}

.g-pagination button[disabled] {
  opacity: 0.5;
  cursor: default;
}
</style>
