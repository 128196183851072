<template>
  <Header :pageTitle="pgTitle" />

  <section class="g_main">
    <side_bar />

    <div class="g_dashboard">
      <div class="g_dashboardHeader">
        <h1>Archives of Hakkani Publishers</h1>
      </div>

      <div class="g_dashBox1">
        <div class="g_dashBox">
          <p>{{ presentStockCounter }}</p>
          <h1>Total Books</h1>
        </div>
        <div class="g_dashBox">
          <p>{{ previousStockCounter }}</p>
          <h1>Total Books Shared</h1>
        </div>
        <div class="g_dashBox">
          <!-- <p>{{ DistributedBooksCounter }}</p> -->
          <p>0000</p>
          <h1>----------</h1>
        </div>
        <div class="g_dashBox">
          <!-- <p>{{ TotalBillCounter }}</p> -->
          <p>0000</p>
          <h1>Institutes<br />(Recieved Support)</h1>
        </div>
        <!-- <div class="g_dashBox">
            <p>{{ TotalLibraryCounter }}</p>
            <h1>Total Institute</h1>
          </div> -->
      </div>
    </div>
  </section>

  <foot_er />
</template>

<script>
import foot_er from "./sections/g-footer.vue";
import Header from "./sections/g_header.vue";
import side_bar from "./sections/g_side_bar.vue";

export default {
  name: "g_dashboard_gift",
  components: {
    Header,
    side_bar,

    foot_er,
  },
  data() {
    return {
      pgTitle: "Dashboard",
      isLoading: false,
      dashInfo: [],
      libraryCategory: "",
      categoryLibL: {},
      mainUser: "",
      role: "",
      userAccess: [],
      economicYear: "",
      specialGrant: "",

      countpreviousStock: 1,
      previousStock: 0,
      previousStockCounter: 100,

      countPresentstock: 1,
      presentStock: 0,
      presentStockCounter: 100,

      countDistributedBooks: 1,
      DistributedBooks: 0,
      DistributedBooksCounter: 100,

      countTotalBooks: 1,
      TotalBooks: 0,
      TotalBooksCounter: 100,

      countTotalLibrary: 1,
      TotalLibrary: 0,
      TotalLibraryCounter: 100,

      countTotalBill: 1,
      TotalBill: 0,
      TotalBillCounter: 100,

      countTotalGrants: 1,
      TotalGrants: 0,
      TotalGrantsCounter: 100,
    };
  },
  methods: {
    selectBook() {
      this.showBook = true;
      this.distributionList = false;
    },
    pending_distribution() {
      this.showBook = false;
      this.distributionList = true;
    },
    handleCancel() {
      this.showBook = false;
      this.distributionList = false;
    },

    startCounter() {
      this.countpreviousStock =
        this.previousStock < 100 ? 1 : this.previousStock - 100;
      this.countPresentstock =
        this.presentStock < 100 ? 1 : this.presentStock - 100;
      this.countDistributedBooks =
        this.DistributedBooks < 100 ? 1 : this.DistributedBooks - 100;
      this.countTotalBooks = this.TotalBooks < 100 ? 1 : this.TotalBooks - 100;
      this.countTotalLibrary =
        this.TotalLibrary < 100 ? 1 : this.TotalLibrary - 100;
      this.countTotalBill = this.TotalBill < 100 ? 1 : this.TotalBill - 100;
      this.countTotalGrants =
        this.TotalGrants < 100 ? 1 : this.TotalGrants - 100;

      setInterval(() => {
        if (this.countpreviousStock <= this.previousStock) {
          this.previousStockCounter =
            this.countpreviousStock <= this.previousStock
              ? this.countpreviousStock++
              : "";
        }
      }, 30);

      setInterval(() => {
        if (this.countPresentstock <= this.presentStock) {
          this.presentStockCounter =
            this.countPresentstock <= this.presentStock
              ? this.countPresentstock++
              : "";
        }
      }, 30);
      setInterval(() => {
        if (this.countDistributedBooks <= this.DistributedBooks) {
          this.DistributedBooksCounter =
            this.countDistributedBooks <= this.DistributedBooks
              ? this.countDistributedBooks++
              : "";
        }
      }, 30);
      setInterval(() => {
        if (this.countTotalBooks <= this.TotalBooks) {
          this.TotalBooksCounter =
            this.countTotalBooks <= this.TotalBooks
              ? this.countTotalBooks++
              : "";
        }
      }, 30);

      setInterval(() => {
        if (this.countTotalLibrary <= this.TotalLibrary) {
          this.TotalLibraryCounter =
            this.countTotalLibrary <= this.TotalLibrary
              ? this.countTotalLibrary++
              : "";
        }
      }, 30);

      setInterval(() => {
        if (this.countTotalBill <= this.TotalBill) {
          this.TotalBillCounter =
            this.countTotalBill <= this.TotalBill ? this.countTotalBill++ : "";
        }
      }, 30);

      setInterval(() => {
        if (this.countTotalGrants <= this.TotalGrants) {
          this.TotalGrantsCounter =
            this.countTotalGrants <= this.TotalGrants
              ? this.countTotalGrants++
              : "";
        }
      }, 30);
    },
  },

  async mounted() {
    this.startCounter();
  },
};
</script>

<style>
.g_main .g_dashboard {
  width: 80%;
  margin: 1%;
  justify-content: center;
}

.g_main .g_dashboard .g_dashboardHeader {
  color: var(--g-text-color);
  margin-bottom: 15px;
  padding: 10px 5px;
  background-color: var(--g-trd-color);
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 2px 4px 6px var(--g-black);
}
.g_main .g_dashboard .g_dashBox1 {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}
.g_dashBox {
  background-color: var(--g-bg2-color);
  height: 140px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1%;
  margin: 5px;
  border-radius: 10px;
}

.g_dashBox p {
  position: relative;
  margin-top: 4px;
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 28px;
  color: var(--g-bg-color);
  background-color: var(--g-text-color);
  width: 150px;
  padding: 7%;
  border-radius: 10px;
  box-shadow: inset 2px 2px 6px var(--g-black);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: count-up 2s ease-in-out forwards;
}

@keyframes count-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.g_dashBox h1 {
  color: var(--g-text-color);
  margin-top: 6px;
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 16px;
}

.g_dashBox2 {
  background-color: red;
  height: 350px;
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: center;
  padding: 0.1%;
  margin: 5px;
  border-radius: 10px;
}
.g_dashBox2 h1 {
  color: var(--g-text-color);
  font-size: 22px;
  border-bottom: 1px solid white;
  font-weight: 500;
}

.g_dashBox2 ul {
  margin: 5px 0px;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
}

.g_dashBox2 ul li {
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
  line-height: 20px;
}

.btn3 {
  background-color: white;
  color: var(--g-trd-color);
  cursor: pointer;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.4px;
}
.btn3:hover {
  background-color: var(--g-trd-color);
  color: white;
  width: 250px;
  letter-spacing: 3px;
  box-shadow: 4px 2px 6px black;
  transition: 0.3s ease-in;
}

g_main .g-seaarchedBooks {
  display: block;
  background-color: var(--g-light-color);
  width: 100%;
  height: 100%;
  margin-top: 0.5%;
  margin-right: 0.5%;
  border-radius: 25px;
  padding-bottom: 50px;
  margin-bottom: 25px;
}

.g_main .g-seaarchedBooks h1 {
  width: auto;
  background-color: var(--g-trd-color);
  border-radius: 25px 25px 0px 0px;
  text-align: center;
  padding: 5px;
  color: var(--g-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
}

.g_main .g-seaarchedBooks .BList {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

table {
  background-color: #fff;
  margin-top: 1.5%;
  width: 95%;
  padding: 1%;
  padding-top: 2%;
  padding-bottom: 4%;
  border-radius: 5px;
  box-shadow: inset -3px 3px 6px var(--g-bg-color);
}

table th {
  color: var(--g-bg-color);
  width: 30%;
  font-size: 16px;
  font-weight: 700;
  border: 2px solid var(--g-bg-color);
  border-radius: 5%;
  text-align: center;
  padding: 0.2%;
}

table th,
td .sl {
  width: 4px;
  padding: 4px 10px;
}
.nameAll {
  width: 100px;
  padding: 4px 2px;
}
.th50 {
  max-width: 40px;
  padding: 4px 2px;
}
.leftAlign {
  text-align: left;
}
table td {
  font-size: 14px;
  font-weight: 450;
  text-align: center;
  border: 2px solid var(--g-bg-color);
  border-top: 0;
  padding: 1px 1px;
}

table td .Bdel,
.Bedit {
  padding: 2px 8px;
  text-decoration: none;
  margin: 3px 2px;
  height: 25px;
  margin-top: 5px;
  color: var(--g-text-color);
  font-size: 14px;
  font-weight: 500;
  background-color: var(--g-light-color);
  border-radius: 5%;
  cursor: pointer;
  transition: all 0.5s linear;
}

table td .Bedit:hover {
  background-color: green;
}
table td .Bdel:hover {
  background-color: red;
  letter-spacing: 1.3px;
}
</style>
