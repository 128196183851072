<template>
  

  <Header :pageTitle="pgTitle" />
  <section class="g_main">
    <side_bar />

    <div class="g-userForm">
      <h1>Book Edit</h1>
      <h5 style="text-align: right; margin-right: 100px; font-size: 14px">
        * Required
      </h5>

      <form @submit.prevent="bookConfirm(true, null)">
        <div class="g-formBox">
          <span>Book Name*</span>
          <input type="text" id="bookName" v-model="bookName" required />
        </div>
        <div class="g-formBox">
          <span>Aurthor Name*</span>
          <input type="text" id="authorName" v-model="authorName" required />
        </div>

        <div class="g-formBox">
          <span>Type*</span>
          <input type="text" id="authorName" v-model="selectedType" required />
        </div>

        <div class="g-formBox">
          <span>Total Pages</span>
          <input type="text" v-model="pages" />
        </div>

        <div class="g-formBox">
          <span>Publisher*</span>
          <input type="text" id="pulisher" v-model="publisher" required />
        </div>

        <div class="g-formBox">
          <span>ISBN*</span>
          <input type="text" id="pulisher" v-model="isbn" required />
        </div>

        <div class="g-formBox" v-if="issn">
          <span>ISSN</span>
          <p>{{ issn ? issn : "   " }}</p>
        </div>

        <div class="g-formBox">
          <span>Year</span>
          <input type="text" id="year" v-model="year" required />
        </div>

        <div class="g-formBox">
          <span>Details</span>
          <input type="text" v-model="details" id="details" />
        </div>

        <input type="submit" value="Update Book Info" class="g-btn" />
      </form>
      <div style="display: flex; justify-content: center; align-items: center">
        <button class="btn" @click="confirmDeleteUser()">Delete</button>
      </div>
    </div>
    <div class="g-userList">
      <h1>Stock List</h1>
      <div class="g-UList">
        <table>
          <tr>
            <th>S. No.</th>
            <th>work Order</th>
            <th>Date</th>
            <th>Price</th>
            <th>Copy</th>
            <th>Buttons</th>
          </tr>
          <tr v-for="(sBook, index) in stockBooks" v-bind:key="index">
            <td>{{ sBook.serialNumber }}</td>
            <td v-if="sBook.workOrderNumber">{{ sBook.workOrderNumber }}</td>
            <td v-else>None</td>
            <td v-if="formatDate(sBook.workOrderDate) != 'Invalid date'">
              {{ formatDate(sBook.workOrderDate) }}
            </td>
            <td v-else>No Date</td>
            <td>{{ sBook.unitPrice }}</td>
            <td>{{ sBook.copies }}</td>
            <td>
              <button
                @click="
                  stockForm(
                    sBook.stockId,
                    sBook.serialNumber,
                    sBook.workOrderNumber,
                    sBook.workOrderDate,
                    sBook.unitPrice,
                    sBook.copies,
                    sBook.coverType
                  )
                "
                class="edit"
              >
                Edit
              </button>
            </td>
          </tr>
        </table>

        <button @click="newStockConfirm" class="g-btn">Add New Stock</button>
      </div>
    </div>
  </section>

  <teleport to="body">
    <div class="bgPop" v-if="stockField">
      <div class="popBg">
        <h2>Update Stock</h2>
        
        <div class="popText">
          <span>Serial Number*:</span>
          <input
            v-model="slNumber"
            type="text"
            placeholder="Enter Work Serial Number"
            required
            />
          </div>
        <div class="popText">
          <span>Work Order*:</span>
          <input
            v-model="workOrder"
            type="text"
            placeholder="Enter Work order Number"
            required
          />
        </div>
        <div class="popText">
          <span>Cover Type</span>
          <select v-model="cover">
            <option disabled value="">Select Cover Type</option>
            <option value="Delux">Delux</option>
            <option value="Paper Back">Paper Back</option>
            <option value="Hard Paper Back">Hard Paper Back</option>
          </select>
        </div>
        <div class="popText">
          <span>Date*:</span>
          <input
            v-model="date"
            type="date"
            placeholder="Enter Work order Date"
            required
          />
        </div>
        <div class="popText">
          <span>Unit Price*:</span>
          <input
            v-model="unitPrice"
            type="text"
            placeholder="Enter Unit Price"
            required
          />
        </div>
        <div class="popText">
          <span>Copies*:</span>
          <input
            v-model="updatedCopies"
            type="text"
           v-bind:placeholder=copies
           required
          />
        </div>

        <div class="actionButtons">
          <button @click="updateStock" class="confirm">Update</button>
          <button @click="handleCancel" class="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="bgPop" v-if="newStock">
      <div class="popBg">
        <h2>Create Stock</h2>
        <div class="popText">
          <span>Serial Number*:</span>
          <input
            v-model="slNumber1"
            type="text"
            placeholder="Enter Serial Number"
            required
          />
        </div>
        <div class="popText">
          <span>Work Order*:</span>
          <input
            v-model="workOrder"
            type="text"
            placeholder="Enter Work order Number"
            required
          />
        </div>
        <div class="popText">
          <span>Date*:</span>
          <input
            v-model="date"
            type="date"
            placeholder="Enter Work order Date"
            required
          />
        </div>
        <div class="popText">
          <span>Cover Type</span>
          <select v-model="cover">
            <option disabled value="">Select Cover Type</option>
            <option value="Delux">Delux</option>
            <option value="Paper Back">Paper Back</option>
            <option value="Hard Paper Back">Hard Paper Back</option>
          </select>
        </div>
        <div class="popText">
          <span>Unit Price*:</span>
          <input
            v-model="unitPrice"
            type="text"
            placeholder="Enter Unit Price"
            required
          />
        </div>
        <div class="popText">
          <span>Copies*:</span>
          <input
            v-model="updatedCopies"
            type="text"
           v-bind:placeholder=copies
            required
          />
        </div>

        <div class="actionButtons">
          <button @click="createStock" class="confirm">Create</button>
          <button @click="handleCancel" class="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="bgPop" v-if="showConfirmation">
      <div class="popBg">
        <h2 v-if="bookU">Update Book Confirmation</h2>
        <h2 v-else>Update Stock Confirmation</h2>
        <div class="popText">
          <p v-if="bookU">Are you sure to update the Books ?</p>
          <p v-else>Are you sure to update the stocks ?</p>
        </div>

        <div class="actionButtons">
          <button @click="updateBook" class="confirm">Update</button>
          <button @click="handleCancel" class="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="body">
    <div class="bgPop" v-if="showDeletation">
      <div class="popBg">
        <h2>Confirm Book Deletation</h2>

        <div class="popText">
          <p>Are you sure to delete the book ?</p>
        </div>

        <div class="actionButtons">
          <button @click="deleteBook()" class="Delete">Delete</button>
          <button @click="handleCancel" class="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </teleport>
  
  <foot_er />
</template>

<script>
import Header from "../sections/g_header.vue";
import side_bar from "../sections/g_side_bar.vue";
import foot_er from "../sections/g-footer.vue"
export default {
  name: "gBookEdit",
  components: {
    Header,
    side_bar,
    foot_er
  },
  data() {
    return {
      pgTitle: "Book Management",
      stockField: false,
      showConfirmation: false,
      showDeletation: false,
      isLoading: false,
      bookU: false,
      workOrder: null,
      date: null,
      economicYear:"",
      bookType: "",
      pages: "",
      bookName: "",
      authorName: "",
      isbn: "",
      issn: "",
      cover:"",
      details: "",
      publisher: "",
      newStock:"",
      year: "",
      copy: "",
      slNumber:"",
      slNumber1:"",
      updatedCopies:0,
      price: "",
      books: [],
      selectedType: "",
      selectedBooks: [],
      stockBooks: [],
      showError: false,
    };
  },
  methods: {
    handleCancel() {
      this.showConfirmation = false;
      this.stockField = false;
      this.showDeletation = false;
      this.newStock=false;
      
    },
  },
};
</script>

<style>

.g_main .g-userForm{
  background-color: var(--g-light-color);
  width: 700px;
  height: auto;
  margin-top: 1.5% ;
  margin-left: 2.5%;
  border-radius: 25px;
  margin-bottom: 25px;

}


.g_main .g-userForm h1{
  background-color: var(--g-trd-color);
  border-radius: 25px 25px 0px 0px;
  text-align: center;
  padding: 5px;
  color: var(--g-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2.0px;
}
.g_main .g-userForm h2{
  border-radius: 25px 25px 0px 0px;
  text-align: right;
  padding-right: 25px;
  color: var(--g-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2.0px;
}

.g_main .g-userForm form{
  display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}
.g_main .g-userForm form .g-formBox{
  margin-top: 5px;
  padding: 5px;
  width: 85%;
  border-radius: 25px ;
  padding-left: 30px;
  display: flex;
  justify-content:space-around;
  align-items: center;
  justify-items: center;
  flex-direction: row;
}


.g_main .g-userForm form .g-formBox span{
  margin: 1px;
  margin-left: 10px;
  height: 40px;
  padding-left: 20px;
  width: 30%;
  color: var(--g-bg-color);
  background-color: var(--g-text-color);
  box-shadow: inset 3px 3px 6px var(--g-black);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
}
.g_main .g-userForm form .g-formBox input,select,p{
  margin: 1px;
  height: 40px;
  padding-left: 20px;
  text-align: left;
  width: 55%;
  color: var(--g-black);
  background-color: var(--g-text-color);
  box-shadow: inset 3px 3px 6px var(--g-black);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  
  display: flex;
  align-items: center;
}
.g_main .g-userForm form .g-formBox select{
  margin: 1px;
  height: 40px;
  border-radius: 0px;
  padding-left: 20px;
  text-align: left;
  width: 59%;
  color: var(--g-black);
  background-color: var(--g-text-color);
  box-shadow: inset 3px 3px 6px var(--g-black);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  
  display: flex;
  align-items: center;
}
.g_main .g-userForm form .g-formBox1{
  margin-top: 15px;
  width: 85%;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
 
}
.g_main .g-userForm form .g-formBox1 label{
  margin-left: 10px;
  width: 20%;
  text-align: center;
  padding: 0.1%;
  color: var(--g-bg-color);
  background-color: var(--g-text-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.1px;
}
.g_main .g-userForm button{
  margin-top: 4%;
  margin-bottom: 2%;
  padding: 0.5% 7%;
  height: 40px;
  color: var(--g-trd-color);
  font-size: 15px;
  font-weight: 450;
  background-color: var(--g-text-color);
  box-shadow: -3px 3px 6px var(--g-Snd-color);
  border-radius: 20px;
}
.g_main .g-userForm button:hover{
  padding: 0.5% 5%;
  color: var(--g-text-color);
  font-size: 20px;
  background-color: var(--g-trd-color);
  box-shadow: -3px 3px 6px var(--g-bg-color);
  letter-spacing: 2px;
  transition: 0.5s ease-in;
  cursor: pointer;
}


.g_main .g-userList{
  background-color: var(--g-light-color);
  width: 600px;
  height: 100%;
  margin-top: 1.5% ;
  margin-left: 4%;
  border-radius: 25px;
  padding-bottom: 50px;
  margin-bottom: 25px;
}

.g_main .g-userList h1{
  background-color: var(--g-trd-color);
  border-radius: 25px 25px 0px 0px;
  text-align: center;
  padding: 5px;
  color: var(--g-text-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2.0px;
}

.g_main .g-userList .g-UList{
  display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.g_main .g-userList .g-UList table{
  background-color: #fff;
  margin-top: 5%;
  width: 95%;
  padding: 1.5%;
  padding-top: 4%;
  padding-bottom: 4%;
  border-radius: 5px;
  box-shadow: inset -3px 3px 6px var(--g-bg-color);
}

.g_main .g-userList .g-UList table th{
  color: var(--g-bg-color);
  width: 30%;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid var(--g-bg-color);
  border-radius: 5%;
  text-align: center;
  padding: 1%;
}

.g_main .g-userList .g-UList table th,td .sl{
  width: 5px;
}

.g_main .g-userList .g-UList table td{
  font-size: 14px;
  font-weight: 450;
  text-align: center;
  border: 2px solid var(--g-bg-color);
  border-top: 0;
  padding: 2px 3px;
}

.g_main .g-userList .g-UList table td .del,.edit{
  text-decoration: none;
  margin: 0;
  width: 55px;
  height: 25px;
  margin: 1px 3px;
  color: var(--g-text-color);
  font-size: 14px;
  font-weight: 500;
  background-color: var(--g-light-color);
  border-radius: 5%;
  cursor: pointer;
  transition: all 0.5s linear;
}

.g_main .g-userList .g-UList table td .edit:hover{
  background-color: green;
  letter-spacing: 1.3px;
}
.g_main .g-userList .g-UList table td .del:hover{
  background-color: red;
  letter-spacing: 1.3px;
}


.g-btn{
  margin-top: 4%;
  margin-bottom: 2%;
  padding: 0.5% 7%;
  height: 40px;
  text-decoration: none;
  color: var(--g-trd-color);
  font-size: 18px;
  font-weight: 450;
  background-color: var(--g-text-color);
  box-shadow: -3px 3px 6px var(--g-Snd-color);
  border-radius: 20px;
}

.g-btn:hover{
  padding: 0.5% 5%;
  color: var(--g-text-color);
  font-size: 20px;
  background-color: var(--g-trd-color);
  box-shadow: -3px 3px 6px var(--g-bg-color);
  letter-spacing: 2px;
  transition: 0.5s ease-in;
  cursor: pointer;
}


</style>
