<template>
  <div class="g_sideBar">
    <router-link to="/dashboard" class="no-underline">
      <div class="sideBox" :class="{ active: $route.path === '/dashboard' }">
        <h1>Dashboard</h1>
      </div>
    </router-link>

    <router-link to="/createUser" class="no-underline">
      <div class="sideBox" :class="{ active: $route.path === '/createUser' }">
        <h1>Create User</h1>
      </div>
    </router-link>

    <router-link to="/bookEntry" class="no-underline">
      <div class="sideBox" :class="{ active: $route.path === '/bookEntry' }">
        <h1>Book Entry</h1>
      </div>
    </router-link>

    <router-link to="/bookList" class="no-underline">
      <div class="sideBox" :class="{ active: $route.path === '/g_book_list' }">
        <h1>Book List</h1>
      </div>
    </router-link>
    <router-link to="/" class="no-underline">
      <div class="sideBox">
        <h1>Log Out</h1>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "g_sideBar",
  data() {
    return {
      userAccess: [],
      role: "",
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style>
.g_main {
  background-color: var(--g-bg-color);
  height: 100%;
  padding: 0;
  border: none;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.g_main .g_sideBar {
  padding: 0;
  padding-bottom: 50px;
  margin: 0;
  min-height: 1000px;
  background-color: var(--g-light-color);
  width: 17%;
  display: inline;
}
.no-underline {
  text-decoration: none;
}

.g_main .g_sideBar .sideBox {
  padding: 7px;
  margin-top: 30px;
  margin-left: 30px;
  color: var(--g-bg-color);
  background-color: var(--g-text-color);
  text-decoration: none;
  border-radius: 500px 0px 0px 500px;
  text-align: center;
  align-items: center;
  box-shadow: inset 7px 7px 8px var(--g-bg-color);
  cursor: pointer;
  letter-spacing: 0.5px;
}

.g_main .g_sideBar .sideBox:hover {
  color: var(--g-bg-color);
  box-shadow: -5px 7px 8px var(--g-bg-color);
  transition: 0.3s ease-in;
  letter-spacing: 2.2px;
}
.g_main .g_sideBar .sideBox.active {
  color: var(--g-text-color);
  background-color: var(--g-trd-color);
  box-shadow: -4px 5px 6px var(--g-text-color);
  transition: 0.3s ease-in;
  letter-spacing: 2.2px;
}

.g_main .g_sideBar .sideBox h1 {
  text-decoration: none;
  margin: 3px;
  font-size: 14px;
  font-weight: 500;
}
</style>
