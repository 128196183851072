<template>
    <div class="main">
        <div class="boxLeft">
            <div class="login_box">
                <img src="../assets/logo.png" alt="">
                <h2> &nbsp; Log In &nbsp;</h2>
                <form @submit.prevent="login">
                    <input type="text" placeholder="Enter Your User Name" v-model="uname" required>
                    <input type="password" placeholder="Enter Your Password" v-model="pword" required>
                    <button type="submit" > Log In</button>
                </form>
                <h3 @click="confirmation">Forget Password</h3> 
            </div>
        </div>
    </div>


    
</template>

<script>
    export default{
        name : 'Log_In ',
        data(){
            return{
                userId: "",
                uname:"",
                pword:"",
                newPassword:"",
                confirmPassword:"",
                showError:false,
                errorMsg:"",
                forgetMail:"",
                OTP:"",
                showChangePassword:false,
                showOTP:false,
                showForgetPassword:false,
                isLoading:false
            }
        },
        methods: {
          login(){
                
  
                        this.$router.push('/bookList');
             
            }
        }

       
    }
    
</script>

<style>

.main {
  background-color: var(--g-bg-color);
  height: 1000px;
  padding: 0;
  border: none;
  display: flex;
  justify-content: left;
  align-items: center;
}

.boxLeft {
  background-color: var(--g-light-color);
  min-height: 1000px;
  width: 50%;
}


.boxLeft .login_box {
  margin-left: 75%;
  margin-top: 20%;
  padding: 10px;
  background-color: var(--g-bg2-color);
  min-height: 500px;
  width: 320px;
  border-radius: 15px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  box-shadow: -15px 15px 15px var(--g-bg-color);
}

.boxLeft .login_box img {
  height: 120px;
  padding: 5px;
  /* background-color: white ; */
  border-radius: 5px;
  /* box-shadow: 2px 2px 3px var(--black); */
  margin-bottom: -2px;
}

.boxLeft .login_box h2 {
  color: var(--g-text-color);
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 5px;
  border-bottom: 10px dotted var(--g-text-color);
}
.boxLeft .login_box h3 {
  color: var(--g-text-color);
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  letter-spacing: 1.2px;
}

.boxLeft .login_box h3:hover {
  color: var(--g-text-color);
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 4px;
  transition: 0.5s ease-in;
  cursor: pointer;
}

.boxLeft .login_box form {
  padding: 10px;
  margin: 2px;
  width: 300px;
  min-height: 200px;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.boxLeft .login_box form input {
  width: 280px;
  height: 35px;
  padding-left: 15px;
  font-size: 14px;
  color: var(--g-black);
  border-radius: 20px;
  box-shadow: -3px 3px 6px var(--g-bg-color);
  letter-spacing: 2px;
}

.boxLeft .login_box form button {
  width: 120px;
  height: 40px;
  color: var(--g-text-color);
  font-size: 20px;
  font-weight: 500;
  background-color: var(--g-bg-color);
  box-shadow: -3px 3px 6px var(--g-Snd-color);
  border-radius: 20px;
}

.boxLeft .login_box form button:hover {
  width: 180px;
  color: var(--g-bg-color);
  font-size: 22px;
  background-color: var(--text-color);
  box-shadow: -3px 3px 6px var(--g-bg-color);
  letter-spacing: 2px;
  transition: 0.5s ease-in;
  cursor: pointer;
}





.btn{
  margin-top: 4%;
  margin-bottom: 2%;
  padding: 0.5% 7%;
  height: 40px;
  text-decoration: none;
  color: var(--g-trd-color);
  font-size: 18px;
  font-weight: 450;
  background-color: var(--g-text-color);
  box-shadow: -3px 3px 6px var(--g-Snd-color);
  border-radius: 20px;
}

.btn:hover{
  padding: 0.5% 5%;
  color: var(--g-text-color);
  font-size: 20px;
  background-color: var(--g-trd-color);
  box-shadow: -3px 3px 6px var(--g-bg-color);
  letter-spacing: 2px;
  transition: 0.5s ease-in;
  cursor: pointer;
}


</style>